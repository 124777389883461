<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('批量查询')"
        :class="crumbs == '批量查询' ? 'active' : ''"
        >批量查询</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : ''"
        >订单查询</span
      >
    </div>
    <!-- 批量订单查询 -->
    <div v-if="crumbs == '批量查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>商户名称</i>
            <el-select
              v-model="query.keyword"
              filterable
              clearable
              :filter-method="searchMchList"
              @visible-change="unfoldMchList"
              @clear="clearMchList"
              @change="changeMch"
              placeholder="商户名称"
              :no-data-text="mchDropData=='' ? '无数据':'可搜索'"
            >
              <el-option
                v-for="item in mchDropData"
                :key="item.mchId"
                :label="item.mchName + ' | ' + item.mchId"
                :value="item.mchId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <i>产品类型</i>
            <el-select
              v-model="query.payType"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="朝夕付" value="BANK_PAY_DAILY_ORDER"></el-option>
              <el-option
                label="任意付"
                value="BANK_PAY_MAT_ENDOWMENT_ORDER"
              ></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>订单状态</i>
            <el-select
              v-model="query.payStatus"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="交易处理中" value="PAYING"></el-option>
              <el-option label="交易失败" value="FAIL"></el-option>
              <el-option label="交易成功" value="REACHED"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>时间类型</i>
            <el-select
              v-model="query.timeType"
              placeholder="创建时间"
              style="width: 130px;"
            >
              <el-option label="创建时间" value="CREATE"></el-option>
              <el-option label="交易时间" value="TRADE"></el-option>
            </el-select>
            <span style="padding: 0 10px"></span>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button class="searchBt" @click="getLists">查询</el-button>
            <el-button
              v-auth="'TRADE:PAY:ORDERS/EXPORT'"
              class="outputBt"
              @click="outputShow"
              >导出</el-button
            > 
          </div>
        </div>
      </div>
      <!-- 中间交易账目 -->
      <div class="jiaoyi">
        <div class="jiaoyi_box">
          <p><i>代付总数（笔）</i></p>
          <p>
            <b>{{ payNum }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>代付金额（元）</i></p>
          <p>
            <b>{{ (payAmount / 100) | formatMoney }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>手续费（元）</i></p>
          <p>
            <b>{{ (totalFee / 100) | formatMoney }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>总金额（元）</i></p>
          <p>
            <b>{{ (totalAmount / 100) | formatMoney }}</b>
          </p>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
              <p>交易时间</p>
            </td>
            <td>
              <p>商户名称</p>
            </td>
            <td>
              <p>代付订单号</p>
              <p>代付流水号</p>
            </td>
            <td>
              <p>账户卡号</p>
              <p>账户名称</p>
            </td>
            <td>
              <p>代付金额(元)</p>
              <p>手续费(元)</p>
            </td>
            <td>
              <p>总金额(元)</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>订单状态</p>
            </td>
            <td style="padding-right: 10px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.mchPayNo }}</p>
              <p>{{ v.plfPayNo }}</p>
            </td>
            <td>
              <p>{{ v.accountNo }}</p>
              <p>{{ v.accountName }}</p>
            </td>
            <td>
              <p>{{ (v.payAmount / 100) | formatMoney }}</p>
              <p>{{ (v.fee / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.totalAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ payType[v.payType] }}</p>
            </td>
            <td>
              <p>{{ payStatus[v.payStatus] }}</p>
            </td>
            <td style="padding-right: 10px">
              <div v-if="v.getSyncShow">
                <p>
                  <i
                    v-auth="'TRADE:PAY:ORDERS/DETAIL'"
                    class="lianjie"
                    @click="showTab(v)"
                    >详情</i
                  >
                </p>
                <p>
                  <i
                    v-auth="'TRADE:PAY:ORDERS/SYNC'"
                    class="lianjie"
                    @click="getSync(v.payId, i)"
                    >同步</i
                  >
                  <i
                    v-auth="'TRADE:PAY:ORDERS/NOTIFY'"
                    v-if="v.payStatus != 'PAYING'"
                    @click="getNotify(v.payId)"
                    class="lianjie"
                    >通知</i
                  >
                </p>
              </div>
              <div v-if="!v.getSyncShow">
                <p>
                  <i
                    v-auth="'TRADE:PAY:ORDERS/DETAIL'"
                    class="lianjie"
                    @click="showTab(v)"
                    >详情</i
                  >
                  <i
                    v-auth="'TRADE:PAY:ORDERS/NOTIFY'"
                    v-if="v.payStatus != 'PAYING'"
                    @click="getNotify(v.payId)"
                    class="lianjie"
                    >通知</i
                  >
                </p>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <!-- 按订单号查询 -->
    <div v-if="crumbs == '订单查询'">
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <!-- <i>代付订单号</i> -->
            <el-select
              v-model="formData.orderNoType"
              style="width: 170px;margin-right: 20px;"
            >
              <el-option value="mchPayNo" label="代付订单号"></el-option>
              <el-option value="plfPayNo" label="代付流水号"></el-option>
            </el-select>
            <el-input
              v-model="formData.orderNo"
              maxlength="128"
              style="width: calc(100% - 170px - 20px)"
              suffix-icon="el-icon-search"
              :placeholder="'搜索' + orderNoType[formData.orderNoType]"
            ></el-input>
          </div>
          <div class="item itemTwo">
            <el-select
              v-model="formData.accountType"
              style="width: 170px;margin-right: 20px;">
              <el-option value="CARD" label="账户卡号"></el-option>
              <el-option value="NAME" label="账户名称"></el-option>
            </el-select>
            <el-input
              v-model="formData.account"
              maxlength="128"
              style="width: calc(100% - 170px - 20px)"
              suffix-icon="el-icon-search"
              :placeholder="'搜索' + accountType[formData.accountType]"
            ></el-input>
          </div>
          <div class="itembtn itemTwobtn">
            <el-button class="searchBt" @click="getDetail">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
              <p>交易时间</p>
            </td>
            <td>
              <p>商户名称</p>
            </td>

            <td>
              <p>代付订单号</p>
              <p>代付流水号</p>
            </td>
            <td>
              <p>账户卡号</p>
              <p>账户名称</p>
            </td>
            <td>
              <p>代付金额(元)</p>
              <p>手续费(元)</p>
            </td>
            <td>
              <p>总金额(元)</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>订单状态</p>
            </td>
            <td style="padding-right: 10px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.mchPayNo }}</p>
              <p>{{ v.plfPayNo }}</p>
            </td>
            <td>
              <p>{{ v.accountNo }}</p>
              <p>{{ v.accountName }}</p>
            </td>
            <td>
              <p>{{ (v.payAmount / 100) | formatMoney }}</p>
              <p>{{ (v.fee / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.totalAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ payType[v.payType] }}</p>
            </td>
            <td>
              <p>{{ payStatus[v.payStatus] }}</p>
            </td>
            <td style="padding-right: 10px">
              <div v-if="v.getSyncShow">
                <p>
                  <i
                    v-auth="'TRADE:PAY:ORDERS/DETAIL'"
                    class="lianjie"
                    @click="showTab(v)"
                    >详情</i
                  >
                </p>
                <p>
                  <i
                    v-auth="'TRADE:PAY:ORDERS/SYNC'"
                    class="lianjie"
                    @click="getSync(v.payId, i)"
                    >同步</i
                  >
                  <i
                    v-auth="'TRADE:PAY:ORDERS/NOTIFY'"
                    v-if="v.payStatus != 'PAYING'"
                    @click="getNotify(v.payId)"
                    class="lianjie"
                    >通知</i
                  >
                </p>
              </div>
              <div v-if="!v.getSyncShow">
                <p>
                  <i
                    v-auth="'TRADE:PAY:ORDERS/DETAIL'"
                    class="lianjie"
                    @click="showTab(v)"
                    >详情</i
                  >
                  <i
                    v-auth="'TRADE:PAY:ORDERS/NOTIFY'"
                    v-if="v.payStatus != 'PAYING'"
                    @click="getNotify(v.payId)"
                    class="lianjie"
                    >通知</i
                  >
                </p>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <el-dialog title="导出代付记录" :visible.sync="outPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">商户名称</div>
          <el-select v-model="outputForm.mchId" placeholder="选择商户">
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>开始日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            v-model="outputForm.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>交易日期</div>
          <el-select
            v-model="selectTimeType"
            @change="timeChange"
            placeholder="选择交易日期"
          >
            <el-option label="今天" value="1"></el-option>
            <el-option label="昨天" value="2"></el-option>
            <el-option label="近7天" value="7"></el-option>
            <el-option label="其它" value="-1"></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>结束日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            :picker-options="endOption"
            v-model="outputForm.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="outPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="output">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import {
  getPays,
  getPaysSearch,
  getAccountsSync,
  getAccountsNotify,
  exportPays,
} from "@/api/trading/payment.js";
import { merchantsDrop , merchantsDropTwo } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    let that = this;
    return {
      query: {
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        pageNo: 1,
        pageSize: 10,
        timeType: "CREATE",
        payType: "",
        payStatus: "",
        keyword: "",
      },
      totalNum: 0,
      tabData: [],
      payNum: 0,
      payAmount: 0,
      totalFee: 0,
      totalAmount: 0,
      payType: {
        BANK_PAY_DAILY_ORDER: "朝夕付",
        BANK_PAY_MAT_ENDOWMENT_ORDER: "任意付",
      },
      payStatus: {
        PAYING: "交易处理中",
        FAIL: "交易失败",
        REACHED: "交易成功",
      },
      formData: {
        orderNoType: "mchPayNo",
        orderNo: "",
        accountType: "CARD",
        account: "",
      },
      orderNoType: {
        mchPayNo: "代付订单号",
        plfPayNo: "代付流水号",
      },
      accountType: {
        CARD: "账户卡号",
        NAME: "账户名称",
      },
      crumbs: "批量查询",
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() <
              new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000 ||
            date.getTime() >
              new Date(
                +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      outPayShow: false, //导出展示
      outputForm: {}, //导出信息
      selectTimeType: "", //导出时间类型
      mchDrop: [], //商户列表

      mchDropData:[], //商户名称
      keywordData:{
        mchId:"",
        mchName:"",
      },

    };
  },
  computed: {
    ...mapState({
      paidqueryDetail: "trading_paidquery",
    }),
  },
  created() {
    if (this.paidqueryDetail) {
      this.query = this.paidqueryDetail.query;
      this.formData = this.paidqueryDetail.formData;
      this.keywordData = this.paidqueryDetail.keywordData;
    }
    if(JSON.stringify(this.keywordData) != '{}' && this.keywordData != undefined && this.keywordData.mchId != ""){
      this.query.keyword = this.keywordData.mchName + ' | ' + this.keywordData.mchId;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.query.pageNo;
    });
    if (this.$route.query.name) {
      this.query.keyword = this.$route.query.name;
    }
    this.getLists(false);
    this.getDrop();
    this.$enter(this.$route.path, this.search);
  },
  methods: {
    ...mapMutations({
      setPaidquery: "trading_setPaidquery",
    }),
    search() {
      if (this.crumbs == "批量查询") {
        this.getLists();
      } else {
        this.getDetail();
      }
    },
    // 获取详情
    getLists(reset = true) {
      if (reset) {
        (this.query.pageNo = 1);
          // (this.query.keyword = this.query.keyword.trim());
      }
      let data = JSON.parse(JSON.stringify(this.query));
      if(this.keywordData != undefined && this.keywordData.mchId != ""){
        data.keyword = this.keywordData.mchId;
      }
      getPays(data).then((res) => {
        if (res) {
          if (this.crumbs == "批量查询") {
            this.tabData = res.resultData.pays;
            if (this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }else{
              this.getSyncType(this.tabData)
            }
           
            this.totalNum = res.resultData.totalNum;
            this.payNum = res.resultData.payNum;
            this.payAmount = res.resultData.payAmount;
            this.totalFee = res.resultData.totalFee;
            this.totalAmount = res.resultData.totalAmount;
            
          }
        }
      });
    },
    // 获取下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 获取搜索详情
    getDetail() {
      let params = {
        orderNoType: this.formData.orderNoType,
        orderNo: this.formData.orderNo.trim(),
        accountType: this.formData.accountType,
        account: this.formData.account.trim(),
      };
      if (!params.orderNo && !params.account) {
        this.$message.error(
          "请输入" +
            this.orderNoType[this.formData.orderNoType] +
            "或" +
            this.accountType[this.formData.accountType]
        );
        return;
      }
      getPaysSearch(params).then((res) => {
        if (res) {
          if (this.crumbs != "批量查询") {
            this.tabData = res.resultData;
            if (this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }else{
              this.getSyncType(this.tabData)
            }
          }
        }
      });
    },
    // 创建时间离当前时间超过15天，不显示同步按钮
    getSyncType(data){
        //取当前时间的毫秒数 - 后台返回的毫秒数 = 间隔时间毫秒数
        var newTime = new Date().getTime()
        for(let i=0;i<data.length;i++){
            let oldTime = new Date(data[i].createTime).getTime()
            if((newTime - oldTime) > 15 * 24 * 3600 * 1000){
              data[i].getSyncShow = false
              // console.log('大于了,不展示')
            }else{
              data[i].getSyncShow = true
              // console.log('小于了,可以展示')
            }
        }
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.tabData = [];
      if (data == "批量查询") {
        this.getLists();
      } else {
        if (this.formData.orderNo || this.formData.account) {
          this.getDetail();
        }
      }
    },
    // 同步
    getSync(id, index) {
      getAccountsSync(id).then((res) => {
        if (res.resultStatus) {
          this.tabData[index].payStatus = res.resultData.orderStatus;
          this.$message.success("同步请求成功");
        }
      });
    },
    // 通知
    getNotify(id) {
      getAccountsNotify(id).then((res) => {
        if (res.resultStatus) {
          this.$message.success("通知请求成功");
        }
      });
    },
    // 跳转详情
    showTab(value) {
      this.$router.push({
        name: "paidquery_qx",
        query: {
          id: value.payId,
        },
      });
    },
    // 导出展示
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        startDate: "",
        endDate: "",
        mchId: "",
      };
      this.selectTimeType = "";
    },
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.startDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.startDate = "";
          this.outputForm.endDate = "";
          break;
      }
    },
    // 导出
    output() {
      if (!this.outputForm.startDate || !this.outputForm.endDate) {
        this.$message.error("导出时间段必选");
        return;
      }
      let data = this.outputForm;
      exportPays(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "代付查询.csv");
          document.body.appendChild(link);
          link.click();
        }
      });
      this.outPayShow = false;
    },

    // 获取商户下拉列表
    _searchAllMch(value) {
      this.mchDropData = [];
      let params = {
        keyword: value,
      };
      merchantsDropTwo(params).then((res) => {
        if (res.resultStatus) {
          this.mchDropData = res.resultData;
          console.log(this.mchDropData);
        }
      });
    },
    // 商户下拉列表输入搜索内容时
    searchMchList(val) {
      if (val) {
        let value = val;
        this._searchAllMch(value);
      } else {
        // 搜索内容为空时还原列表
        this._searchAllMch();
      }
    },
    // 商户下拉框展开/收起时触发
    unfoldMchList(e) {
      if (e) {
        //  展开时
        this._searchAllMch();
      } else {
        //  折叠时
        this.mchDropData = [];
      }
    },
    // 商户下拉搜索框清空搜索内容时
    clearMchList() {
      this.query.keyword = ""; //清空选中的商户id
      this.keywordData = {};
      this._searchAllMch(); //重新获取所有商户下拉列表
    },
    changeMch(e) {
      this.query.keyword = e;
      let mchId  = this.query.keyword;
      console.log( this.query.keyword );
      let result = this.mchDropData.filter(e=>e.mchId === mchId)
      if(result && result.length > 0){
        console.log(result[0])
        this.keywordData = result[0];
      }
    },     

    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },

  },
  beforeDestroy() {
    let data = {
      query: this.query,
      formData: this.formData,
      keywordData: this.keywordData,
    };
    this.setPaidquery(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime{
  width: 63.5%;
}
.con_from .lineP .item i {
  width: 64px;
}

.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 64px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 130px - 64px - 44.67px - 10px) / 2);
}
.con_from .lineP .item .inputW {
  width: calc((100% - 64px - 24.67px - 10px) / 2);
}
.con_from .lineP .item .inputW ::v-deep .el-input__inner{
  padding: 0px 0px 0px 15px !important;
}
.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}
.overAccount_ul_title i {
  color: #48b8b6;
}
.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

</style>
